import React from "react";
import {Helmet} from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";

import ButtonLink from "../components/common/ButtonLink";
import ShadowEgg from "../components/common/ShadowEgg";

import * as classes from "./404.module.scss";

export default function NotFoundPage() {
    return (
        <section className={classes.container}>
            <Helmet>
                <title>404</title>
            </Helmet>
            <div className={classes.contentContainer}>
                <h1>404</h1>
                <p>Страница не найдена</p>
                <ButtonLink to="/" variant="FILLED" color="ACCENT-2">Перейти на главную</ButtonLink>
            </div>
            <ShadowEgg className={classes.egg}>
                <StaticImage
                    src="img/404.jpg"
                    alt="Производство медицинских изделий, транспортных сред"
                    layout="fixed"
                    width={925} />
            </ShadowEgg>
        </section>
    );
}
